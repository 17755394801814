import React from "react";
import JobList from "../components/JobList";

export default function Jobs() {
  return (
    <div>
      <JobList maintitle="Job Vacancies" subtitle="Our Services" />
    </div>
  );
}
