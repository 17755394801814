import React from "react";
import Team from "../components/Team";
import TeamBanner from "../components/TeamBanner";

export default function TeamPage() {
  return (
    <div>
      <TeamBanner />
      <Team />
    </div>
  );
}
